
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
//@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat.core();
@import "primeflex/primeflex.css";
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$cov-hr-primary: mat.define-palette(mat.$indigo-palette);
//$cov-hr-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
//
//// The warn palette is optional (defaults to red).
//$cov-hr-warn: mat.define-palette(mat.$red-palette);
//
//// Create the theme object. A theme consists of configurations for individual
//// theming systems such as "color" or "typography".
//$cov-hr-theme: mat.define-light-theme((
//  color: (
//    primary: $cov-hr-primary,
//    accent: $cov-hr-accent,
//    warn: $cov-hr-warn,
//  )
//));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

//@include mat.all-component-themes($cov-hr-theme);

//@import "assets/scss/app";
//@import "../node_modules/primeicons/primeicons.css";
@import "assets/css/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.pac-container {
  z-index: 100000 !important;
}

.container-fluid {
  width: 100%;
  padding-right: 3rem;
  padding-left: 3rem;
  margin-right: auto;
  margin-left: auto;
}


$header-height: 70px;
$grid-gutter-width:1.5rem !default;
$footer-height: 60px;

.page-content {
  padding-top: 200px;
}

.active {
  color: #817CCD !important;
}
